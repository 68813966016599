<template>
  <div>
    <v-form @submit.prevent="submitResponse">
      <p class="mb-3">Using the data in the table below, answer each of the questions below:</p>
      <p class="mb-5 pl-14">
        <chemical-latex content="A + B -> C + D" />
      </p>

      <v-simple-table style="max-width: 400px" class="mb-5">
        <thead>
          <tr>
            <th style="text-align: center">
              <stemble-latex content="$\text{[A] (M)}$" />
            </th>
            <th style="text-align: center">
              <stemble-latex content="$\text{[B] (M)}$" />
            </th>
            <th style="text-align: center">
              <stemble-latex content="$\text{[D] (M)}$" />
            </th>
            <th style="text-align: center; font-size: 1.1rem">
              Initial Rate (M&nbsp;min<sup>-1</sup>)
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td style="text-align: center">
              <number-value :value="Aconc1" />
            </td>
            <td style="text-align: center">
              <number-value :value="Bconc1" />
            </td>
            <td style="text-align: center">
              <number-value :value="Dconc1" />
            </td>
            <td style="text-align: center">
              <latex-number :number="rate1.toFixed(2)" />
            </td>
          </tr>
          <tr>
            <td style="text-align: center">
              <latex-number :number="Aconc2.toFixed(4)" />
            </td>
            <td style="text-align: center">
              <number-value :value="Bconc1" />
            </td>
            <td style="text-align: center">
              <number-value :value="Dconc1" />
            </td>
            <td style="text-align: center">
              <latex-number :number="rate2.toFixed(2)" />
            </td>
          </tr>
          <tr>
            <td style="text-align: center">
              <number-value :value="Aconc1" />
            </td>
            <td style="text-align: center">
              <number-value :value="Bconc1" />
            </td>
            <td style="text-align: center">
              <latex-number :number="Dconc2.toFixed(4)" />
            </td>
            <td style="text-align: center">
              <latex-number :number="rate3.toFixed(2)" />
            </td>
          </tr>

          <tr>
            <td style="text-align: center">
              <number-value :value="Aconc1" />
            </td>
            <td style="text-align: center">
              <latex-number :number="Bconc2.toFixed(3)" />
            </td>
            <td style="text-align: center">
              <number-value :value="Dconc1" />
            </td>
            <td style="text-align: center">
              <latex-number :number="rate4.toFixed(2)" />
            </td>
          </tr>
        </tbody>
      </v-simple-table>

      <p class="mb-n3">a) What is the rate law for the reaction?</p>
      <v-radio-group v-model="inputs.rateLaw" class="pl-8 mb-0" :disabled="!allowEditing">
        <v-radio
          v-for="option in optionsRateLaw"
          :key="'pt-1-' + option.value"
          :value="option.value"
          :label="option.text"
        >
          <template #label>
            <stemble-latex :content="option.text" />
          </template>
        </v-radio>
      </v-radio-group>

      <p class="mb-3 mt-1">b) What is the rate constant for the reaction?</p>

      <calculation-input
        v-model="inputs.rateConstant"
        prepend-text="$\text{k:}$"
        append-text="$\text{Units not given}$"
        class="mb-5"
        :disabled="!allowEditing"
      />

      <p class="mb-n3">c) What are the correct units for the rate constant?</p>

      <v-radio-group v-model="inputs.units" class="pl-8 mb-0" :disabled="!allowEditing">
        <v-radio
          v-for="option in optionsUnits"
          :key="'pt-3-' + option.value"
          class="my-1"
          :value="option.value"
          :label="option.text"
        >
          <template #label>
            <stemble-latex :content="option.text" />
          </template>
        </v-radio>
      </v-radio-group>

      <p class="mb-4 mt-4">
        d) In a few sentences, please describe how you determined the rate law and rate constant for
        the reaction.
      </p>
      <div>
        <v-textarea v-model="inputs.explainedResponse" class="mb-4" autogrow rows="6" outlined />
      </div>
    </v-form>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import ChemicalLatex from '../displayers/ChemicalLatex';
import LatexNumber from '../displayers/LatexNumber';
import CalculationInput from '../inputs/CalculationInput';
import NumberValue from '../NumberValue';
import StembleLatex from '@/tasks/components/StembleLatex';
import STextarea from '@/common/components/STextareaShort.vue';

export default {
  name: 'Question547V2',
  components: {
    STextarea,
    StembleLatex,
    ChemicalLatex,
    LatexNumber,
    CalculationInput,
    NumberValue,
  },
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      // Put a list of inputs or computed properties here to be sent for marking
      inputs: {
        rateLaw: null,
        rateConstant: null,
        units: null,
      },
      optionsRateLaw: [
        {text: '$\\text{k}~\\ce{[A]^2[B]}~\\ce{[D]^{-1}}$', value: 'a'},
        {text: '$\\text{k}~\\ce{[A]^2[B]^{-1}[D]^{1/2}}$', value: 'b'},
        {text: '$\\text{k}~\\ce{[A]}~\\ce{[B]^{1/2}[D]^{-1/2}}$', value: 'c'},
        {text: '$\\text{k}~\\ce{[A]^{-1}[B]}~\\ce{[D]^{1/2}}$', value: 'd'},
        {text: '$\\text{k}~\\ce{[A]^{-1}[B]}~\\ce{[D]^{-1/2}}$', value: 'e'},
        {text: 'None of the above', value: 'f'},
      ],
      optionsUnits: [
        {text: '$\\text{M}^{3/2} ~ \\text{min}^{-1}$', value: 'M3over2perMin'},
        {text: '$\\text{M}^{1/2} ~ \\text{min}^{-1}$', value: 'M1over2perMin'},
        {text: '$\\text{M} ~ \\text{min}^{-1}$', value: 'MperMin'},
        {text: '$\\text{min}^{-1}$', value: 'perMin'},
        {text: '$\\text{M}^{-1/2} ~ \\text{min}^{-1}$', value: 'perM1over2perMin'},
        {text: '$\\text{M}^{-1} ~ \\text{min}^{-1}$', value: 'perMperMin'},
        {text: 'None of the above', value: 'none'},
      ],
    };
  },
  computed: {
    Aconc1() {
      return this.taskState.getValueBySymbol('Aconc1').content;
    },
    Bconc1() {
      return this.taskState.getValueBySymbol('Bconc1').content;
    },
    Dconc1() {
      return this.taskState.getValueBySymbol('Dconc1').content;
    },
    scaleA() {
      return 2;
    },
    scaleB() {
      return 2;
    },
    scaleD() {
      return 4;
    },
    orderA() {
      return 1;
    },
    orderB() {
      return 0.5;
    },
    orderD() {
      return -0.5;
    },
    rateConstant() {
      return 1940;
    },
    Aconc2() {
      return this.Aconc1.toFloat() * this.scaleA;
    },
    Bconc2() {
      return this.Bconc1.toFloat() * this.scaleB;
    },
    Dconc2() {
      return this.Dconc1.toFloat() * this.scaleD;
    },
    rate1() {
      return (
        ((this.rateConstant * 3) / 2) *
        this.Aconc1.toFloat() ** this.orderA *
        this.Bconc1.toFloat() ** this.orderB *
        this.Dconc1.toFloat() ** this.orderD
      );
    },
    rate2() {
      return (
        ((this.rateConstant * 3) / 2) *
        this.Aconc2 ** this.orderA *
        this.Bconc1.toFloat() ** this.orderB *
        this.Dconc1.toFloat() ** this.orderD
      );
    },
    rate3() {
      return (
        ((this.rateConstant * 3) / 2) *
        this.Aconc1.toFloat() ** this.orderA *
        this.Bconc1.toFloat() ** this.orderB *
        this.Dconc2 ** this.orderD
      );
    },
    rate4() {
      return (
        ((this.rateConstant * 3) / 2) *
        this.Aconc1.toFloat() ** this.orderA *
        this.Bconc2 ** this.orderB *
        this.Dconc1.toFloat() ** this.orderD
      );
    },
  },
};
</script>
